import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, IconButton } from "@mui/material";
import {
  Check,
  Cancel,
  Search as SearchIcon,
  Close,
} from "@mui/icons-material";
import TextField from "../../components/TextField";
import QRCodeScanner from "../../components/QRCodeScannerX";
import TicketSearchResult from "./TicketSearchResult";
import { ITicket } from "../../interfaces/ITicket";
import CircularLoading from "../../components/CircularLoading";
import Alert from "../../components/ConfirmIconButton";

interface Props {
  activeTab: string;
  onScanned: (decodedText: string, decodedResult: any) => void;
  onScanError: (errMessage: string) => void;
  ticket?: ITicket;
  onSearchTicket: (ticketNumber: string) => void;
  loading?: boolean;
  loadingLabel: string;
  error: boolean;
  message: string;
  onCancel: () => void;
  cameraOpen: boolean;
  clearInput: boolean;
}

export default function TicketVerifying(props: Props) {
  const [state, setState] = useState({
    ticketNumber: "",
  });

  const onInputChange = (value) => {
    setState((prev) => ({ ...prev, ticketNumber: value }));
    if (value.length === 10) {
      props.onSearchTicket(value);
    }
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, ticketNumber: "" }));
  }, [props.clearInput]);

  return (
    <React.Fragment>
      {props.activeTab === "code" ? (
        <Grid container direction="column" alignItems="center">
          <TextField
            name="TicketNumber"
            label="Ticket Number"
            onChange={(value) => {
              onInputChange(value);
            }}
            fullWidth
            endAndorment={
              <IconButton
                onClick={() => props.onSearchTicket(state.ticketNumber)}
              >
                <SearchIcon fontSize="medium" />
              </IconButton>
            }
            disabled={props.loading}
            value={state.ticketNumber}
            autoFocus
            clearInput={props.clearInput}
          />
        </Grid>
      ) : (
        <Grid container direction="column" alignItems="center">
          <QRCodeScanner
            open={props.cameraOpen}
            onScanned={props.onScanned}
            onError={props.onScanError}
          />
        </Grid>
      )}
      <Box width="100%">
        {props.message !== "" && (
          <React.Fragment>
            <Alert
              label={props.message}
              type={props.error ? "error" : "success"}
            />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                startIcon={<Close />}
                variant="outlined"
                onClick={props.onCancel}
              >
                Close
              </Button>
            </Box>
          </React.Fragment>
        )}
        {props.ticket && (
          <TicketSearchResult
            ticket={props.ticket}
            loading={props.loading}
            onCancel={props.onCancel}
            loadingLabel={props.loadingLabel}
          />
        )}
        {props.loading && !props.ticket && (
          <CircularLoading label={props.loadingLabel} />
        )}
      </Box>
    </React.Fragment>
  );
}
