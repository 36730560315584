import { IUserToken } from "../interfaces/IAuth";

export default (userClaims: IUserToken) => {
  const appId = process.env.APP_ID;
  const { apps = [], organizations = [] } = userClaims;

  const currentApp = apps.find((app) => app.appId === appId);
  const currentOrg = organizations.find(
    (or) => or.apps && or.apps.findIndex((a) => a.appId === appId) > -1
  );

  const orgApp = currentOrg?.apps.find((a) => a.appId === appId);

  const userOrg = {
    orgId: currentOrg?.orgId,
    role: orgApp?.role || currentApp?.role,
  };

  return {
    userApp: currentApp,
    userOrg,
  };
};
