import React, { useEffect, useRef, useState } from "react";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import TicketCheckingComponent from "../components/TicketChecking";
import apiService from "../../services/apiService";
import { IEvent } from "../../interfaces/IEvent";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/configureStore";
import { playSuccessSound, playErrorSound } from "../../utils/soundPlay";
import {
  getPaidTicket,
  loadPaidTickets,
  updatePaidTicket,
  syncPaidTicket,
  clearCachedTickets,
} from "../../services/indexDbService";
import { ITicket } from "../../interfaces/ITicket";
import CircularIndeterminate from "../../components/CircularLoading";
import Alert from "../../components/Alert";

export const TicketChecking: React.FC = () => {
  const selectedEvent: IEvent = useAppSelector(
    (store: RootState) => store.selectedEvent
  );
  const [state, setState] = useState({
    ticket: undefined,
    loading: false,
    loadingLabel: "Loading...",
    error: false,
    message: "",
    cameraOpen: false,
    mode: "code",
    clearInput: false,
    loadingTickets: false,
    online: navigator.onLine,
  });

  useEffect(() => {
    // Load paid tickets for caching
    if (selectedEvent.id) {
      setState((prev) => ({ ...prev, loadingTickets: true }));
      loadPaidTickets(selectedEvent.id, onTicketsLoaded);
      setInterval(async () => {
        await syncPaidTicket(selectedEvent.id);
      }, 1000 * 30);
    }
  }, [selectedEvent.id]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      clearCachedTickets();
    };

    // Check network connection in intervals
    const networkCheckTimer = setInterval(async () => {
      await handleOnlineStatusChange();
    }, 5 * 60 * 1000); // 5 minutes interval

    return () => {
      clearCachedTickets();
      clearInterval(networkCheckTimer);
    };
  }, []);

  const handleOnlineStatusChange = async () => {
    try {
      await fetch(`https://www.example.com`, {
        method: "HEAD",
        mode: "no-cors",
      });
      setState((prev) => ({
        ...prev,
        online: true,
        clearInput: !prev.clearInput,
      }));
    } catch (error) {
      setState((prev) => ({ ...prev, online: false }));
    }
  };

  const onTicketsLoaded = () => {
    setState((prev) => ({ ...prev, loadingTickets: false }));
  };

  const onScanned = async (text: string, result: any) => {
    await onCheckinTicket(text);
  };

  const onScanError = (message: string) => {
    setState((prev) => ({
      ...prev,
      error: true,
      message: message || "",
      cameraOpen: prev.mode === "scan",
    }));
    // playErrorSound();
  };

  const onCheckinTicket = async (ticketNumber: string) => {
    setState((prev) => ({
      ...prev,
      loading: true,
      loadingLabel: "Checking in ticket...",
      error: false,
      message: "",
    }));

    const cashed: any = await getPaidTicket(ticketNumber);
    if (!cashed) {
      playErrorSound();
      setState((prev) => ({
        ...prev,
        ticket: undefined,
        loading: false,
        error: true,
        message: `Ticket ${ticketNumber} not found, make sure you have correct event selected, ticket was generated or contact support!`,
        clearInput: !prev.clearInput,
      }));
      return;
    }
    if (cashed && cashed.status === "checked-in") {
      playErrorSound();
      setState((prev) => ({
        ...prev,
        ticket: undefined,
        loading: false,
        error: true,
        message: `Ticket ${ticketNumber} already checked-in!`,
        clearInput: !prev.clearInput,
      }));
      return;
    }

    const cacheResponse = await updatePaidTicket(cashed as unknown as ITicket);
    if (cacheResponse.error) {
      const response = await apiService({
        path: `/tickets/${ticketNumber}/checkin?eventId=${selectedEvent.id}`,
        method: "GET",
      });
      if (response.error) {
        playErrorSound();
      } else {
        playSuccessSound();
        await updatePaidTicket(state.ticket as unknown as ITicket);
      }
      setState((prev) => ({
        ...prev,
        ticket: undefined,
        loading: false,
        error: response.error || false,
        message: response.message || "",
        clearInput: !prev.clearInput,
      }));
    } else {
      playSuccessSound();
      setState((prev) => ({
        ...prev,
        ticket: undefined,
        loading: false,
        error: false,
        message: cacheResponse.message || "",
        clearInput: !prev.clearInput,
      }));
      await apiService({
        path: `/tickets/${ticketNumber}/checkin?eventId=${selectedEvent.id}`,
        method: "GET",
      });
    }
  };

  const onCancel = () => {
    setState((prev) => ({
      ...prev,
      ticket: undefined,
      cameraOpen: prev.mode === "scan",
      message: "",
      clearInput: !prev.clearInput,
    }));
  };

  const toggleCheckMode = () => {
    setState((prev) => ({
      ...prev,
      mode: prev.mode === "code" ? "scan" : "code",
      cameraOpen: prev.mode === "code",
    }));
  };

  return state.loadingTickets ? (
    <CircularIndeterminate label="Loading event tickets, please wait..." />
  ) : (
    <React.Fragment>
      {!state.online ? (
        <Alert
          message="You have been offline for 5 minutes, this may cause data syncing problem, please connect to internet."
          type="error"
          title="Network Not Available!"
        />
      ) : (
        <TicketCheckingComponent
          onScanned={onScanned}
          onScanError={onScanError}
          activeTab={state.mode}
          loading={state.loading}
          loadingLabel={state.loadingLabel}
          onCheckinTicket={onCheckinTicket}
          error={state.error}
          message={state.message}
          onCancel={onCancel}
          cameraOpen={state.cameraOpen}
          clearInput={state.clearInput}
        />
      )}
      <Fab
        size="small"
        color="primary"
        aria-label="Check mode"
        sx={{
          position: "absolute",
          bottom: 60,
          right: 28,
        }}
        onClick={toggleCheckMode}
      >
        <Tooltip title="Check check mode" arrow>
          {state.mode === "code" ? <TextFieldsIcon /> : <QrCodeScannerIcon />}
        </Tooltip>
      </Fab>
    </React.Fragment>
  );
};

export default TicketChecking;
