import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import { Html5Qrcode } from "html5-qrcode";
import QrScanner from "qr-scanner";

interface Props {
  open: boolean;
  onScanned: (decodedText: string, decodedResult: any) => void;
  onError: (errMessage: string) => void;
}

export default function QRCodeScanner(props: Props) {
  const element = "camera-view";
  const cameraFeedRef = useRef<any>(null);
  const [camera, setCamera] = useState(false);

  const startCamera = () => {
    if (cameraFeedRef.current) {
      cameraFeedRef.current.start().catch((err: any) => {
        console.log("Start Error: ", err);

        // Start failed, handle it.
        props.onError("Failed processing QR code, try again");
      });
      setCamera(true);
    }
  };

  const stopCamera = () => {
    if (cameraFeedRef.current) {
      cameraFeedRef.current.stop();
    }
  };

  useEffect(() => {
    const video: HTMLVideoElement = document.getElementById(
      element
    ) as HTMLVideoElement;
    const qrScanner = new QrScanner(
      video,
      (result: any) => {
        props.onScanned(result.data, result.data);
      },
      {
        highlightCodeOutline: true,
        highlightScanRegion: true,
        onDecodeError(error) {
          // console.log(error);
        },
      }
    );

    QrScanner.listCameras()
      .then((cameras) => {
        cameraFeedRef.current = qrScanner;
        setCamera(true);
        startCamera();
      })
      .catch((error) => {
        console.log("Error getting cameras: ", error);
      });

    // This method will trigger user permissions

    // Html5Qrcode.getCameras()
    //   .then((devices) => {
    //     /**
    //      * devices would be an array of objects of type:
    //      * { id: "id", label: "label" }
    //      */
    //     if (devices && devices.length) {
    //       const cameraId = devices[0].id;
    //       // .. use this to start scanning.
    //       setCamera(true);
    //       startCamera();
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("Error: ", err);

    //     // handle err
    //     props.onError("Failed processing QR code, try again");
    //   });
    return () => {
      stopCamera();
      qrScanner.destroy();
    };
  }, []);

  useEffect(() => {
    if (camera) {
      if (props.open) {
        startCamera();
      } else {
        stopCamera();
      }
    }
  }, [props.open]);

  return (
    <Box
      id="camera-container"
      sx={{
        width: {
          md: "80%",
          sm: "100%",
          display: !props.open ? "none" : "block",
        },
      }}
    >
      <video
        id="camera-view"
        className="viewport"
        width="100%"
        height="100%"
      ></video>
    </Box>
  );
}
