import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SellIcon from "@mui/icons-material/Sell";
import VerifyIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { NavLink } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import BottomNav from "../../components/BottomNavigation";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/configureStore";
import { IUserToken } from "../../interfaces/IAuth";
import { logout } from "../../services/authService";
import identityService from "../../services/identityService";
import Alert from "../../components/Alert";
import { isBrowserCompatible } from "../../utils/checkCompatibility";

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */

  children: JSX.Element;
}

export default function AppLayout(props: Props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const dispatch = useAppDispatch();

  const currenctUser: IUserToken = useAppSelector(
    (store: RootState) => store.currenctUser
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotificationMenuOpen = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose = async () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    await identityService({
      path: "/auth/logout",
      method: "GET",
    });
    await logout();
    localStorage.removeItem("au");
    window.location.assign(
      `${process.env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`
    );
  };

  const onActiveTabChange = (tab: "code" | "scan") => {
    //
  };

  const profileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="profileMenu"
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem component={NavLink} to="/account">
        My account
      </MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const notificationMenu = (
    <Menu
      anchorEl={anchorEl2}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="notificationMenu"
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={Boolean(anchorEl2)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Notification 1</MenuItem>
      <MenuItem onClick={handleMenuClose}>Notification 2</MenuItem>
      <MenuItem onClick={handleMenuClose}>Notification 3</MenuItem>
    </Menu>
  );

  const drawer = (
    <Box>
      <Toolbar />
      <Divider />
      <List>
        <ListItem
          component={NavLink}
          to="/"
          disablePadding
          sx={{ color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem
          component={NavLink}
          to="/tickets/checkin"
          disablePadding
          sx={{ color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <CheckCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Check-in" />
          </ListItemButton>
        </ListItem>
        <ListItem
          component={NavLink}
          to="/tickets/verify"
          disablePadding
          sx={{ color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <VerifyIcon />
            </ListItemIcon>
            <ListItemText primary="Verify Ticket" />
          </ListItemButton>
        </ListItem>
        <ListItem
          component={NavLink}
          to="/tickets/selling"
          disablePadding
          sx={{ color: "black" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <SellIcon />
            </ListItemIcon>
            <ListItemText primary="Selling" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Noneho Ticketing
          </Typography>
          <Box>
            <IconButton
              aria-label="show new notifications"
              color="inherit"
              aria-controls="notificationMenu"
              aria-haspopup="true"
              onClick={handleNotificationMenuOpen}
            >
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="profileMenu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {currenctUser.photo ? (
                <img
                  src={currenctUser.photo}
                  style={{ width: 30, height: 30, borderRadius: "50%" }}
                />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
          </Box>
          {profileMenu}
          {notificationMenu}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {!isBrowserCompatible() ? (
          <Alert
            message="Your browser is not compatible with operations of this app, upgrade it or use a different one (Latest Google Chrome Recommended)."
            type="error"
            title="Browser Not Supported!"
          />
        ) : (
          props.children
        )}
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
        }}
      >
        <BottomNav onChangeActiveTab={onActiveTabChange} />
      </Box>
    </Box>
  );
}
