import React from "react";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, Theme } from "@mui/material/styles";
import { store } from "./redux/configureStore";
import appThemes from "./assets/styles/themes";
import AppRoute from "./routes";
import AuthProvider from "./providers/AuthProvider";

const themeApp: Theme = appThemes.light;

export const App: React.FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={themeApp}>
      <CssBaseline />
      <AuthProvider>
        <AppRoute />
      </AuthProvider>
    </ThemeProvider>
  </Provider>
);
