import { ITicket } from "../interfaces/ITicket";
import apiService from "./apiService";
import indexDbConfig from "../config/indexDb";

let db: IDBDatabase;

export const loadPaidTickets = async (
  eventId: string,
  onTicketsLoaded: () => void
) => {
  const response = await apiService({
    path: `/tickets?eventId=${eventId}`,
    method: "GET",
  });

  if (!response.error) {
    window.indexedDB.deleteDatabase(indexDbConfig.name);
    const request = window.indexedDB.open(indexDbConfig.name);
    request.onupgradeneeded = (event) => {
      const target = event.target as IDBOpenDBRequest;
      db = target.result;
      const objectStore = db.createObjectStore(indexDbConfig.paidTicketStore, {
        keyPath: indexDbConfig.keyPath,
      });
      objectStore.createIndex(indexDbConfig.keyPath, indexDbConfig.keyPath, {
        unique: true,
      });
      objectStore.transaction.oncomplete = (event) => {
        // Store values in the newly created objectStore.
        const ticketsObjectStore = db
          .transaction(indexDbConfig.paidTicketStore, "readwrite")
          .objectStore(indexDbConfig.paidTicketStore);
        response.results.forEach((ticket) => {
          ticketsObjectStore.put(ticket);
        });
        db.close();
        onTicketsLoaded()
      };
    };
    request.onerror = (event) => {
      //
    };
  }
};

export const getPaidTicket = async (ticketNumber: string) => {
  return await new Promise((resolve, reject) => {
    const requestdb = window.indexedDB.open(indexDbConfig.name);
    requestdb.onsuccess = (event) => {
      const target = event.target as IDBOpenDBRequest;
      db = target.result;
      const transaction = db.transaction(
        [indexDbConfig.paidTicketStore],
        "readonly"
      );
      const objectStore = transaction.objectStore(
        indexDbConfig.paidTicketStore
      );
      const request = objectStore.get(ticketNumber);
      request.onerror = (event) => {
        return reject("Error retrieving from index db");
      };
      request.onsuccess = (event) => {
        const ticket: ITicket = request.result;
        db.close();
        return resolve(ticket);
      };
    };
    requestdb.onerror = (ev) => {
      return reject("Error opening indexdb");
    };
  });
};

export const loadOfflineTickets = async (eventId: string) => {
  const response = await apiService({
    path: `/tickets/offline?eventId=${eventId}`,
    method: "GET",
  });

  if (!response.error) {
    window.indexedDB.deleteDatabase(indexDbConfig.name);
    const request = window.indexedDB.open(indexDbConfig.name);
    request.onupgradeneeded = (event) => {
      const target = event.target as IDBOpenDBRequest;
      db = target.result;
      const objectStore = db.createObjectStore(
        indexDbConfig.offlineTicketStore,
        {
          keyPath: indexDbConfig.keyPath,
        }
      );
      objectStore.createIndex(indexDbConfig.keyPath, indexDbConfig.keyPath, {
        unique: true,
      });
      objectStore.transaction.oncomplete = (event) => {
        // Store values in the newly created objectStore.
        const ticketsObjectStore = db
          .transaction(indexDbConfig.offlineTicketStore, "readwrite")
          .objectStore(indexDbConfig.offlineTicketStore);
        response.results.forEach((ticket) => {
          ticketsObjectStore.add(ticket);
        });
        db.close();
      };
    };
    request.onerror = (event) => {
      //
    };
  }
};

export const getOfflineTicket = async (ticketNumber: string) => {
  return await new Promise((resolve, reject) => {
    const requestdb = window.indexedDB.open(indexDbConfig.name);
    requestdb.onsuccess = (event) => {
      const target = event.target as IDBOpenDBRequest;
      db = target.result;
      const transaction = db.transaction(
        [indexDbConfig.offlineTicketStore],
        "readonly"
      );
      const objectStore = transaction.objectStore(
        indexDbConfig.offlineTicketStore
      );
      const request = objectStore.get(ticketNumber);
      request.onerror = (event) => {
        return reject("Error retrieving from index db");
      };
      request.onsuccess = (event) => {
        const ticket: ITicket = request.result;
        db.close();
        return resolve(ticket);
      };
    };
    requestdb.onerror = (ev) => {
      return reject("Error opening indexdb");
    };
  });
};

export const updateOfflineTicket = async (ticket: ITicket) => {
  return await new Promise((resolve, reject) => {
    const requestdb = window.indexedDB.open(indexDbConfig.name);
    requestdb.onsuccess = (event) => {
      const target = event.target as IDBOpenDBRequest;
      db = target.result;
      const transaction = db.transaction(
        [indexDbConfig.offlineTicketStore],
        "readwrite"
      );
      const objectStore = transaction.objectStore(
        indexDbConfig.offlineTicketStore
      );
      ticket.status = "sold-out";
      ticket.soldAt = new Date().toISOString();
      const request = objectStore.put(ticket);
      request.onerror = (event) => {
        db.close();
        return reject({ message: "Error updating ticket", error: true });
      };
      request.onsuccess = (event) => {
        db.close();
        return resolve({ message: "Ticket sold out successfuly", error: true });
      };
    };
    requestdb.onerror = (ev) => {
      return reject({ message: "Error updating ticket", error: true });
    };
  });
};

export const updatePaidTicket = async (
  ticket: ITicket
): Promise<{ message: string; error?: boolean }> => {
  return await new Promise((resolve, reject) => {
    const requestdb = window.indexedDB.open(indexDbConfig.name);
    requestdb.onsuccess = (event) => {
      const target = event.target as IDBOpenDBRequest;
      db = target.result;
      const transaction = db.transaction(
        [indexDbConfig.paidTicketStore],
        "readwrite"
      );
      const objectStore = transaction.objectStore(
        indexDbConfig.paidTicketStore
      );
      ticket.checkinAt = new Date().toISOString();
      ticket.status = "checked-in";
      const request = objectStore.put(ticket);
      request.onerror = (event) => {
        db.close();
        return reject({ message: "Error updating ticket", error: true });
      };
      request.onsuccess = (event) => {
        db.close();
        return resolve({ message: "Ticket checked-in successfully" });
      };
    };
    requestdb.onerror = (ev) => {
      return reject({ message: "Error updating ticket", error: true });
    };
  });
};

export const syncPaidTicket = async (eventId: string) => {
  const response = await apiService({
    path: `/tickets?eventId=${eventId}`,
    method: "GET",
  });
  if (!response.error) {
    await new Promise((resolve, reject) => {
      const requestdb = window.indexedDB.open(indexDbConfig.name);
      requestdb.onsuccess = (event) => {
        const target = event.target as IDBOpenDBRequest;
        db = target.result;
        const transaction = db.transaction(
          [indexDbConfig.paidTicketStore],
          "readwrite"
        );
        const objectStore = transaction.objectStore(
          indexDbConfig.paidTicketStore
        );

        response.results.forEach((ticket: ITicket) => {
          const request = objectStore.put(ticket);
          request.onerror = (event) => {
            db.close();
            return reject("Error updating ticket index db");
          };
          request.onsuccess = (event) => {
            db.close();
            return resolve(ticket);
          };
        });
      };
      requestdb.onerror = (ev) => {
        return reject("Error opening indexdb");
      };
    });
  }
};

export const clearCachedTickets = (offlineTickets = false) => {
  const requestdb = window.indexedDB.open(indexDbConfig.name);
  requestdb.onsuccess = (event) => {
    const target = event.target as IDBOpenDBRequest;
    db = target.result;
    const objectS = offlineTickets
      ? indexDbConfig.offlineTicketStore
      : indexDbConfig.paidTicketStore;
    const transaction = db.transaction([objectS], "readwrite");
    const objectStore = transaction.objectStore(objectS);
    const request = objectStore.clear();
    request.onerror = (err) => {
      //clear request failed
    };
  };
  requestdb.onerror = (ev) => {
    // Failed clearing db
  };
};
