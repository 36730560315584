import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import moment from "moment";
import { ITicket } from "../../interfaces/ITicket";
import CircularLoading from "../../components/CircularLoading";
import { getSignedUrl } from "../../services/signUrlService";

interface Props {
  ticket?: ITicket;
  loading?: boolean;
  onCancel: () => void;
  loadingLabel: string;
}

export default function TicketSearchResult(props: Props) {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  useEffect(() => {
    if (props.ticket) {
      getSignedUrl(props.ticket.qrCodeUrl).then((result) => {
        setQrCodeUrl(result);
      });
    }
  }, [props.ticket]);
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography align="center" variant="h6">
        Ticket Inforamtion
      </Typography>
      <img src={qrCodeUrl} width={200} />
      <List>
        <ListItem disablePadding>
          <ListItemText
            primary="Event"
            secondary={props.ticket?.eventInfo.eventName}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary="Ticket Number"
            secondary={props.ticket?.ticketItemNumber}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary="Ticket Source"
            secondary={props.ticket?.ticketSource || "Online"}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Tier" secondary={props.ticket?.tierName} />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary="Status"
            secondary={
              <Typography variant="h6" color="secondary">
                {props.ticket?.status}{" "}
                {props.ticket?.status === "checked-in" &&
                  moment
                    .utc(props.ticket.checkinAt)
                    .add(2, "h")
                    .format("h:mm A")}
              </Typography>
            }
          />
        </ListItem>
      </List>
      {props.loading && <CircularLoading label={props.loadingLabel} />}
      <Grid container spacing={2} justifyContent="center" mb={10}>
        <Grid item>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<Cancel />}
            disabled={props.loading}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
