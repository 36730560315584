import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  List,
  ListItemText,
  ListItem,
} from "@mui/material";
import { Check, Cancel, Search as SearchIcon } from "@mui/icons-material";
import { ITicket, IOfflineTicket } from "../../interfaces/ITicket";
import CircularLoading from "../../components/CircularLoading";
import SellInfoComponent from "./SellTicketInfo";
import { ISellTicketPayInfo } from "../../interfaces/IEvent";
import moment from "moment";
import { getSignedUrl } from "../../services/signUrlService";

interface Props {
  ticket?: ITicket;
  loading?: boolean;
  onSellTicket: (payload: ISellTicketPayInfo) => void;
  onCancel: () => void;
  loadingLabel: string;
}

export default function TicketSearchResult(props: Props) {
  const [state, setState] = useState({
    customerModel: false,
  });
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  useEffect(() => {
    if (props.ticket) {
      getSignedUrl(props.ticket.qrCodeUrl).then((result) => {
        setQrCodeUrl(result);
      });
    }
  }, [props.ticket]);
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography align="center" variant="h6">
        Ticket Inforamtion
      </Typography>
      <img src={qrCodeUrl} width={200} />
      <List>
        <ListItem disablePadding>
          <ListItemText
            primary="Event"
            secondary={props.ticket?.eventInfo.eventName}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary="Ticket ID"
            secondary={props.ticket?.ticketItemNumber}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText primary="Tier" secondary={props.ticket?.tierName} />
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            primary="Status"
            secondary={
              <Typography variant="h6" color="secondary">
                {props.ticket?.status}{" "}
                {props.ticket?.status === "sold-out" &&
                  moment
                    .utc(props.ticket.soldAt)
                    .add(2, "h")
                    .format("ddd, MMM Do, hh:mm A")}
              </Typography>
            }
          />
        </ListItem>
      </List>
      {props.loading && <CircularLoading label={props.loadingLabel} />}
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button
            onClick={() =>
              setState((prev) => ({ ...prev, customerModel: true }))
            }
            variant="outlined"
            color="primary"
            startIcon={<Check />}
            disabled={props.ticket?.status === "sold-out" || props.loading}
          >
            Sell Ticket
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<Cancel />}
            disabled={props.loading}
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      {props.ticket && (
        <SellInfoComponent
          open={state.customerModel}
          ticket={props.ticket}
          onConfirm={(payload: ISellTicketPayInfo) => {
            setState((prev) => ({ ...prev, customerModel: false }));
            return props.onSellTicket(payload);
          }}
          onCancel={() => {
            setState((prev) => ({ ...prev, customerModel: false }));
            props.onCancel();
          }}
        />
      )}
    </Box>
  );
}
