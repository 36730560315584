/* eslint-disable default-param-last */
import { FETCH_EVENTS, FETCH_EVENT,FETCH_PAID_TICKETS } from "./types";
import { APIReducerResult } from "../../interfaces/IReducers";
import { MainAPIResponse } from "../../interfaces/IAPIResponse";

const initialState: APIReducerResult = {
  loading: false,
  data: {},
};

export default class EventsReducers {
  static eventsReducer(
    state = initialState,
    action: { type: string; payload: MainAPIResponse }
  ) {
    switch (action.type) {
      case `${FETCH_EVENTS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_EVENTS}_FULFILLED`:        
        return {
          ...state,
          loading: false,
          data: action.payload,
        };

      case `${FETCH_EVENTS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static eventReducer(
    state = initialState,
    action: { type: string; payload: MainAPIResponse }
  ) {
    switch (action.type) {
      case `${FETCH_EVENT}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_EVENT}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_EVENT}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }

  static paidTicketsReducer(
    state = initialState,
    action: { type: string; payload: MainAPIResponse }
  ) {
    switch (action.type) {
      case `${FETCH_PAID_TICKETS}_PENDING`:
        return {
          ...state,
          loading: true,
        };
      case `${FETCH_PAID_TICKETS}_FULFILLED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      case `${FETCH_PAID_TICKETS}_REJECTED`:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
      default:
        return state;
    }
  }
}
