import React from "react";
import TicketVerifyingContainer from "../containers/TicketVerifying";
import { Container, Typography } from "@mui/material";
import AppLayout from "../layouts/AppLayout";
import EventSelector from "../containers/EventSelector";

export const TicketChecking: React.FC = () => {
  return (
    <AppLayout>
      <React.Fragment>
        <EventSelector />
        <Container
          maxWidth="sm"
          sx={{
            padding: 2,
            border: "1px solid grey",
            height: "fit-content",
            minHeight: "80vh",
            borderRadius: 3,
          }}
        >
          <Typography variant="h5" align="center">
            Event Ticket Verifying
          </Typography>
          <TicketVerifyingContainer />
        </Container>
      </React.Fragment>
    </AppLayout>
  );
};

export default TicketChecking;
