import React from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IEvent } from "../../interfaces/IEvent";
import Loading from "../../components/CircularLoading";
import Alert from "../../components/Alert";

interface Props {
  events: IEvent[];
  onChange: (selectedEvent: IEvent) => void;
  eventName: string;
  loading: boolean;
  error?: boolean;
  message: string;
}
export default function EventSelector(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onMenuItemClick = (selectedEvent: IEvent) => {
    props.onChange(selectedEvent);
    setAnchorEl(null);
  };

  const onMenuClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const eventsMenu = (
    <React.Fragment>
      {props.loading ? (
        <Loading label="Loading events...." />
      ) : props.error ? (
        <Alert message={props.message} type="error" />
      ) : (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id="eventsMenu"
          keepMounted
          transformOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {props.events &&
            props.events.map((event) => (
              <MenuItem onClick={() => onMenuItemClick(event)} key={event?.id}>
                {event?.name}
              </MenuItem>
            ))}
        </Menu>
      )}
    </React.Fragment>
  );

  return (
    <Box>
      <Button
        endIcon={<ArrowDropDownIcon />}
        aria-controls="eventsMenu"
        aria-haspopup="true"
        onClick={onMenuClick}
        sx={{ textTransform: "none" }}
      >
        {props.eventName}
      </Button>
      {eventsMenu}
    </Box>
  );
}
