import * as React from "react";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import SellIcon from "@mui/icons-material/Sell";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VerifyIcon from "@mui/icons-material/Search";

interface Props {
  onChangeActiveTab: (tab: "code" | "scan") => void;
}

export default function BottomNav(props: Props) {
  const [value, setValue] = React.useState(0);

  const navigate = useNavigate();

  const onChangeLinkTab = (link: string) => {
    navigate(link);
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          component={NavLink}
          to="/"
        />
        <BottomNavigationAction
          label="Check-in"
          icon={<CheckCircleOutlineIcon />}
          component={NavLink}
          to="/tickets/checkin"
        />
        <BottomNavigationAction
          label="Verify"
          icon={<VerifyIcon />}
          component={NavLink}
          to="/tickets/verify"
        />
        <BottomNavigationAction
          label="Selling"
          icon={<SellIcon />}
          component={NavLink}
          to="/tickets/selling"
        />
      </BottomNavigation>
    </Paper>
  );
}
