import React from "react";
import { makeStyles } from "@mui/styles";
import Alert, { AlertColor } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default (props: {
  title?: string;
  message: string;
  type: AlertColor;
  action?: any;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity={props.type} action={props.action}>
        {props.title && <AlertTitle>{props.title}</AlertTitle>}
        {props.message}
      </Alert>
    </div>
  );
};
