import { AxiosResponse } from "axios";
import identityHttpInstance from "../config/identityHttpConfig";
import { MainAPIResponse } from "../interfaces/IAPIResponse";

export default async ({
  path,
  method,
  data,
  options,
}: {
  path: string;
  method: string;
  data?: object;
  options?: object;
}): Promise<MainAPIResponse> => {
  try {
    let response: AxiosResponse;
    switch (method) {
      case "PATCH":
        response = await identityHttpInstance.patch(path, data, {
          ...options,
        });
        return response.data;
      case "GET":
        response = await identityHttpInstance.get(path, {
          ...options,
        });
        return response.data;
      case "POST":
        response = await identityHttpInstance.post(path, data, {
          ...options,
        });
        return response.data;
      case "DELETE":
        response = await identityHttpInstance.delete(path, {
          ...options,
        });
        return response.data;
      default:
        return {
          error: true,
          message: "Unsupported http method!",
          status: 400,
        };
    }
  } catch (error: any) {
    return {
      error: true,
      status:
        error.response && error.response.data && error.response.data.status,
      message:
        error.message && error.message.includes("Network Error")
          ? "Connection failed, please check internet connection or contact administrator"
          : error.response.data.message || error.response.data,
    };
  }
};
