import React, { useState, useEffect } from "react";
import { localAction } from "../../redux/localActions";
import EventSelectorComponent from "../components/EventSelector";
import { FETCH_EVENTS } from "../../redux/events/types";
import apiService from "../../services/apiService";
import { IEvent } from "../../interfaces/IEvent";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CHANGE_SELECTED_EVENT } from "../../redux/localState/types";
import { RootState } from "../../redux/configureStore";
import { MainAPIResponse } from "../../interfaces/IAPIResponse";
import { APIReducerResult } from "../../interfaces/IReducers";
import { mainAPIAction } from "../../redux/APIActions";

export default function EventSelector() {
  const dispatch = useAppDispatch();
  const events = useAppSelector((store) => store.events);
  const selectedEvent: IEvent = useAppSelector(
    (store: RootState) => store.selectedEvent
  );
  const [state, setState] = useState({
    events: [],
  });
  const loadEvents = async () => {
    const response = await apiService({
      method: "GET",
      path: "/events/concerts",
    });
    if (!response.error)
      setState((prev) => ({ ...prev, events: response.results }));
  };
  useEffect(() => {
    mainAPIAction(
      {
        method: "GET",
        path: "/events/concerts",
        actionType: FETCH_EVENTS,
      },
      dispatch
    );
  }, []);

  const onChange = (selectedEvent: IEvent) => {
    dispatch(localAction(CHANGE_SELECTED_EVENT, selectedEvent)(dispatch));
  };

  return (
    <EventSelectorComponent
      events={events.data.results}
      onChange={onChange}
      eventName={selectedEvent.name}
      loading={events.loading}
      error={events.data.error}
      message={events.data.message || ""}
    />
  );
}
