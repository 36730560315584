import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.IDENTITY_API,
  headers: {
    Accepted: "appication/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.headers) {
      config.headers.appId = process.env.APP_ID;
      const user: any = JSON.parse(localStorage.getItem("au") || "{}");
      if (user.atkn) {
        config.headers.authorization = user.atkn;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
