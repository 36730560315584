import React, { useState } from "react";
import TicketSellingContainer from "../containers/TicketSelling";
import { Container, Grid, Box, Typography } from "@mui/material";
import AppLayout from "../layouts/AppLayout";
import EventSelector from "../containers/EventSelector";

export const TicketChecking: React.FC = () => {
  const [state, setState] = useState({});
  return (
    <AppLayout>
      <React.Fragment>
        <EventSelector />
        <Container
          maxWidth="sm"
          sx={{
            padding: 2,
            border: "1px solid grey",
            height: "fit-content",
            minHeight: "80vh",
            borderRadius: 3,
          }}
        >
          <Typography variant="h5" align="center">
            Event Ticket Selling
          </Typography>
          <TicketSellingContainer />
        </Container>
      </React.Fragment>
    </AppLayout>
  );
};

export default TicketChecking;
