import successSound from "../assets/audio/success.wav"
import failSound from "../assets/audio/Error.mp3"

export const playSuccessSound = async () => {
  try {
    await new Audio(successSound).play();
  } catch (error) {
    console.log("Play sound failed: ", error);
  }
};

export const playErrorSound = async () => {
  try {
    await new Audio(failSound).play();
  } catch (error) {
    console.log("Play sound failed: ", error);
  }
};
