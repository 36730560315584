import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Check, Cancel } from "@mui/icons-material";
import TextField from "../../components/TextField";
import { ITicket } from "../../interfaces/ITicket";
import { ISellTicketPayInfo } from "../../interfaces/IEvent";

interface Props {
  open: boolean;
  onConfirm: (payload: ISellTicketPayInfo) => void;
  ticket: ITicket;
  onCancel: () => void;
}

export default (props: Props): JSX.Element => {
  const [state, setState] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const onInputChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onConfirm = () => {
    const customer = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phoneNumber: state.phoneNumber,
    };
    const payment = {
      currency: props.ticket.tierCurrency,
      amount: props.ticket.tierPrice,
    };
    props.onConfirm({
      customer,
      payment,
      ticketNumber: props.ticket?.ticketItemNumber,
    });
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onCancel}>
        <DialogTitle>Ticket Selling Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Provide customer and payment information for the ticket to be
            processed.
          </DialogContentText>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            fullWidth
            onChange={(value) => onInputChange("firstName", value)}
            value={state.firstName}
            autoFocus
          />
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            fullWidth
            onChange={(value) => onInputChange("lastName", value)}
            value={state.lastName}
          />
          <TextField
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            onChange={(value) => onInputChange("email", value)}
            value={state.email}
          />
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            fullWidth
            onChange={(value) => onInputChange("phoneNumber", value)}
            value={state.phoneNumber}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TextField
              id="currency"
              name="currency"
              label="Payment Currency"
              fullWidth
              onChange={(value) => {
                //
              }}
              disabled
              value={props.ticket.tierCurrency}
            />
            <TextField
              id="amount"
              name="amount"
              label="Payment Amount"
              fullWidth
              onChange={(value) => {
                //
              }}
              disabled
              value={`${props.ticket.tierPrice}`}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onConfirm}
            variant="outlined"
            color="primary"
            startIcon={<Check />}
          >
            Confirm
          </Button>
          <Button
            onClick={props.onCancel}
            variant="outlined"
            color="inherit"
            startIcon={<Cancel />}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
