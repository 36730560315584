import React, { useEffect, useRef, useState } from "react";
import { Box, FormControl, FormHelperText, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: `2px solid ${theme.palette.primary.light}`,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.light,
      },
    },
    "& label.Mui-focused": {
      color: theme.palette.text.primary,
    },
  },
  rootWhite: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: `2px solid ${theme.palette.primary.light}`,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.light,
      },
    },
    "& label.Mui-focused": {
      color: theme.palette.common.white,
    },
  },
}));

export default ({
  id,
  name,
  label,
  variant = "outlined",
  type = "text",
  onChange,
  multiline,
  size = "small",
  helperText,
  fullWidth,
  newClasses,
  white,
  endAndorment,
  startAndorment,
  disabled,
  value,
  autoFocus = false,
  clearInput
}: {
  id?: string;
  name: string;
  label: string;
  variant?: "filled" | "outlined" | "standard" | undefined;
  type?: string;
  onChange: (e: any) => void;
  multiline?: boolean;
  size?: "medium" | "small" | undefined;
  helperText?: string;
  fullWidth?: boolean;
  newClasses?: any;
  white?: boolean;
  startAndorment?: JSX.Element;
  endAndorment?: JSX.Element;
  disabled?: boolean;
  value: string;
  autoFocus?: boolean;
  clearInput?: boolean;
}): JSX.Element => {
  const classes = newClasses || useStyles();

  const ref: any = useRef(null);

  useEffect(() => {
      ref.current?.focus();
  }, [clearInput]);

  return (
    <Box my={2}>
      <FormControl fullWidth={fullWidth}>
        <TextField
          className={white ? classes.rootWhite : classes.root}
          id={id}
          name={name}
          label={label}
          variant={variant}
          type={type}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          multiline={multiline}
          size={size}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{startAndorment}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">{endAndorment}</InputAdornment>
            ),
          }}
          disabled={disabled}
          value={value}
          onPaste={(e) => {
            e.preventDefault();
            const text = e.clipboardData
              ? e.clipboardData.getData("text/plain")
              : "";
            onChange(text);
            return true;
          }}
          autoFocus={autoFocus}
          inputRef={ref}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};
