import React from "react";
import reactDOM from "react-dom";
import { App } from "./App";

window.addEventListener("load", () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("./service-worker.js");
  }
});

reactDOM.render(<App />, window.document.getElementById("root"));
