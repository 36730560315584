/* eslint-disable default-param-last */
import {
  CHANGE_SELECTED_EVENT,
  SET_CURRENT_USER,
} from "./types";
import { IEvent } from "../../interfaces/IEvent";
import { IUserToken } from "../../interfaces/IAuth";

export default class LocalReducers {
  static selectedEventReducer(
    state: IEvent = {
      id: "",
      name: "Select event",
    },
    action: { type: string; payload: IEvent }
  ) {
    switch (action.type) {
      case `${CHANGE_SELECTED_EVENT}`:
        return action.payload;
      default:
        return state;
    }
  }

  static currentUserReducer(
    state = {},
    action: { type: string; payload: IUserToken }
  ) {
    switch (action.type) {
      case `${SET_CURRENT_USER}`:
        return action.payload;
      default:
        return state;
    }
  }
}
