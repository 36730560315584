import React from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Check from "@mui/icons-material/CheckOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default (props: {
  type: "success" | "error";
  label: string;
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <IconButton
        color={props.type === "error" ? "error" : "success"}
        size="large"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 0,
          width: "fit-content",
        }}
      >
        {props.type === "error" ? (
          <ErrorOutlineIcon fontSize="large" />
        ) : (
          <Check fontSize="large" />
        )}
        {props.label}
      </IconButton>
    </Box>
  );
};
