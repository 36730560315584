function isIndexedDBSupported(): boolean {
  return !!(
    window.indexedDB ||
    (window as any).mozIndexedDB ||
    (window as any).webkitIndexedDB ||
    (window as any).msIndexedDB
  );
}

function isServiceWorkerSupported(): boolean {
  return !!navigator.serviceWorker;
}

function isBackgroundSyncSupported(): boolean {
  return !!(
    "SyncManager" in window &&
    "serviceWorker" in navigator &&
    "SyncManager" in window
  );
}

export const isBrowserCompatible = () => {
  const isIndexedDB = isIndexedDBSupported();
  const isServiceWorker = isServiceWorkerSupported();
  const isBackgroundSync = isBackgroundSyncSupported();

  return isIndexedDB && isServiceWorker && isBackgroundSync;
};
