import { Dispatch } from "redux";
import mainAPIService from "../services/apiService";

export const mainAPIAction = async (
  {
    method,
    path,
    data,
    options,
    actionType,
  }: {
    method: string;
    path: string;
    data?: object;
    options?: object;
    actionType: string;
  },
  dispatch: Dispatch
) => {
  dispatch({
    type: `${actionType}_PENDING`,
  });
  const response = await mainAPIService({
    method,
    path,
    data,
    options,
  });
  if (response.error) {
    dispatch({
      type: `${actionType}_REJECTED`,
      payload: response,
    });
  } else {
    dispatch({
      type: `${actionType}_FULFILLED`,
      payload: response,
    });
  }
};
