import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import { eventsReducers } from "./events";
import { localReducers } from "./localState";

const reducers = combineReducers({
  ...eventsReducers,
  ...localReducers
});

export const store = createStore(
  reducers,
  {},
  composeWithDevTools(applyMiddleware(thunk, promise))
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
