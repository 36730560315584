import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TicketChecking from "../views/pages/TicketChecking";
import TicketSelling from "../views/pages/TicketSelling";
import HomePage from "../views/pages/HomePage";
import TicketVerifying from "../views/pages/TicketVerifying";

export const AppRouter: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/" element={<TicketChecking />} />
      <Route path="/tickets/checkin" element={<TicketChecking />} />
      <Route path="/tickets/verify" element={<TicketVerifying />} />
      <Route path="/tickets/selling" element={<TicketSelling />} />
    </Routes>
  </Router>
);

export default AppRouter;
