import React, { useEffect, useState } from "react";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import TicketVerifyingComponent from "../components/TicketVerifying";
import apiService from "../../services/apiService";
import { IEvent } from "../../interfaces/IEvent";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/configureStore";
import { playSuccessSound, playErrorSound } from "../../utils/soundPlay";
import {
  getPaidTicket,
  loadPaidTickets,
  updatePaidTicket,
  syncPaidTicket,
  clearCachedTickets,
} from "../../services/indexDbService";
import { ITicket } from "../../interfaces/ITicket";
import CircularIndeterminate from "../../components/CircularLoading";

export const TicketVerifying: React.FC = () => {
  const selectedEvent: IEvent = useAppSelector(
    (store: RootState) => store.selectedEvent
  );

  useEffect(() => {
    // Load paid tickets for caching
    if (selectedEvent.id) {
      setState((prev) => ({ ...prev, loadingTickets: true }));
      loadPaidTickets(selectedEvent.id, onTicketsLoaded);
      setInterval(async () => {
        await syncPaidTicket(selectedEvent.id);
      }, 1000 * 60);
    }
  }, [selectedEvent.id]);

  useEffect(() => {
    return () => {
      clearCachedTickets();
    };
  }, []);

  const [state, setState] = useState({
    ticket: undefined,
    loading: false,
    loadingLabel: "Loading...",
    error: false,
    message: "",
    cameraOpen: false,
    mode: "code",
    clearInput: false,
    loadingTickets: false,
  });

  const onTicketsLoaded = () => {
    setState((prev) => ({ ...prev, loadingTickets: false }));
  };

  const searchTicket = async (ticketNumber: string) => {
    setState((prev) => ({
      ...prev,
      loading: true,
      loadingLabel: "Searching ticket...",
      error: false,
      message: "",
      cameraOpen: false,
    }));

    const cashed: any = await getPaidTicket(ticketNumber);
    if (cashed) {
      setState((prev) => ({
        ...prev,
        ticket: cashed,
        loading: false,
        error: false,
        message: "",
      }));
      playSuccessSound();
      return;
    }
    const response = await apiService({
      path: `/tickets/${ticketNumber}?eventId=${selectedEvent.id}`,
      method: "GET",
    });
    setState((prev) => ({
      ...prev,
      ticket: response.results,
      loading: false,
      error: response.error || false,
      message: response.message || "",
    }));
    if (response.error) {
      playErrorSound();
    } else {
      playSuccessSound();
    }
  };

  const onScanned = async (text: string, result: any) => {
    await searchTicket(text);
  };

  const onScanError = (message: string) => {
    setState((prev) => ({
      ...prev,
      error: true,
      message: message || "",
      cameraOpen: prev.mode === "scan",
    }));
    // playErrorSound();
  };

  const onCancel = () => {
    setState((prev) => ({
      ...prev,
      ticket: undefined,
      cameraOpen: prev.mode === "scan",
      message: "",
      clearInput: !prev.clearInput,
    }));
  };

  const toggleCheckMode = () => {
    setState((prev) => ({
      ...prev,
      mode: prev.mode === "code" ? "scan" : "code",
      cameraOpen: prev.mode === "code",
    }));
  };

  return state.loadingTickets ? (
    <CircularIndeterminate label="Loading event tickets, please wait..." />
  ) : (
    <React.Fragment>
      <TicketVerifyingComponent
        onScanned={onScanned}
        onScanError={onScanError}
        activeTab={state.mode}
        ticket={state.ticket}
        onSearchTicket={searchTicket}
        loading={state.loading}
        loadingLabel={state.loadingLabel}
        error={state.error}
        message={state.message}
        onCancel={onCancel}
        cameraOpen={state.cameraOpen}
        clearInput={state.clearInput}
      />
      <Fab
        size="small"
        color="primary"
        aria-label="Check mode"
        sx={{
          position: "absolute",
          bottom: 60,
          right: 28,
        }}
        onClick={toggleCheckMode}
      >
        <Tooltip title="Check check mode" arrow>
          {state.mode === "code" ? <TextFieldsIcon /> : <QrCodeScannerIcon />}
        </Tooltip>
      </Fab>
    </React.Fragment>
  );
};

export default TicketVerifying;
