import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import identityService from "../services/identityService";
import { auth } from "../config/firebase";
import { loginWithCustomToken } from "../services/authService";
import Loading from "../components/CircularLoading";
import FixedAlert from "../components/Alert";
import { localAction } from "../redux/localActions";
import currentUserClaims from "../utils/currentUserClaims";
import { IUserToken } from "../interfaces/IAuth";
import { useAppDispatch } from "../redux/hooks";
import { SET_CURRENT_USER } from "../redux/localState/types";

export default function AuthProvider(props: {
  children: JSX.Element;
}): JSX.Element {
  const [state, setState] = useState({
    loading: true,
    setupComplete: false,
    error: "",
    firstTime: false,
  });
  const dispatch = useAppDispatch();

  const handleAuthCheck = async () => {
    const resp = await identityService({
      path: "/auth/status",
      method: "GET",
    });
    if (resp.error && resp.status === 401) {
      window.location.assign(
        `${process.env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`
      );
    } else if (resp.error) {
      setState((prev) => ({ ...prev, setupComplete: true, loading: false }));
    } else {
      const currentUser: IUserToken = JSON.parse(
        localStorage.getItem("au") || "{}"
      );
      if (
        !currentUser.uid ||
        (resp.results && currentUser.uid !== resp.results.uid)
      ) {
        setState((prev) => ({ ...prev, firstTime: true, loading: true }));
        const user: any = await loginWithCustomToken(
          resp.results && resp.results.customToken
        );
        if (user?.error) {
          setState((prev) => ({
            ...prev,
            error: user?.message,
            setupComplete: true,
            loading: true,
          }));
        } else
          setState((prev) => ({
            ...prev,
            setupComplete: true,
            error: user.message,
            loading: true,
          }));
      } else {
        setState((prev) => ({
          ...prev,
          setupComplete: true,
          loading: true,
        }));
      }
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("autoReload")) {
      handleAuthCheck();
    } else {
      localStorage.removeItem("autoReload");
    }

    const onAuthChange = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        try {
          const userData = await currentUser.getIdTokenResult(true);
          const user = {
            displayName: currentUser.displayName,
            email: currentUser.email,
            photo: currentUser.photoURL,
            uid: currentUser.uid,
            phoneNumber: currentUser.phoneNumber,
            emailVerified: currentUser.emailVerified,
            claims: currentUserClaims(userData.claims),
          };
          localStorage.setItem(
            "au",
            JSON.stringify({
              ...user,
              atkn: userData.token,
            })
          );
          dispatch(localAction(SET_CURRENT_USER, user)(dispatch));
          setState((prev) => ({ ...prev, setupComplete: true }));
        } catch (error) {
          console.log(error);
          setState((prev) => ({ ...prev, loading: false }));
        }
      } else {
        if (state.setupComplete) {
          window.location.assign(
            `${process.env.IDENTITY_UI}/login?redUrl=${window.location.protocol}//${window.location.host}${window.location.pathname}`
          );
        } else {
          setState((prev) => ({
            ...prev,
            loading: !state.setupComplete,
            error:
              "Authentication failed, reload the page or contact administrator",
          }));
        }
      }
    });

    setInterval(async () => {
      const token = await auth.currentUser?.getIdToken(true);
      const currentUser = JSON.parse(localStorage.getItem("au") || "{}");
      currentUser.atkn = token;
      localStorage.setItem("au", JSON.stringify(currentUser));
    }, 40 * 60 * 1000);
  }, []);

  useEffect(() => {
    if (state.firstTime && state.setupComplete) {
      localStorage.setItem("autoReload", "on first time");
      window.location.reload();
    } else {
      setState((prev) => ({ ...prev, loading: !state.setupComplete }));
    }
  }, [state.firstTime, state.setupComplete]);

  return (
    <div>
      {state.loading ? (
        <Box padding={2}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Loading label="Please wait a moment..." />
          </Grid>
        </Box>
      ) : state.error && state.setupComplete ? (
        <Box padding={2}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <FixedAlert
              title={state.error}
              type="info"
              message={"Authentication failed, reload the page"}
            />
          </Grid>
        </Box>
      ) : (
        props.children
      )}
    </div>
  );
}
